define("discourse/plugins/discourse-instant-search/discourse/components/search-results", ["exports", "@glimmer/component", "@ember/service", "truth-helpers", "discourse/lib/formatter", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _truthHelpers, _formatter, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchResults extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dInstantSearch", [_service.service]))();
    #dInstantSearch = (() => (dt7948.i(this, "dInstantSearch"), void 0))();
    get infiniteHitsClasses() {
      return {
        root: "",
        list: "fps-result-entries",
        item: "fps-result",
        loadMore: ["btn"]
      };
    }
    get customHitTemplate() {
      return {
        item: hit1 => {
          switch (this.args.searchType) {
            case "topics":
              return this.topicsHitTemplate(hit1);
            case "posts":
              return this.postsHitTemplate(hit1);
            case "chat_messages":
              return this.chatMessagesHitTemplate(hit1);
            case "users":
              return this.usersHitTemplate(hit1);
            default:
              return this.topicsHitTemplate(hit1);
          }
        }
      };
    }
    topicsHitTemplate(hit1) {
      const author1 = this.buildAuthorHTML(hit1.author_username);
      const date1 = this.buildDateHTML(hit1.created_at);
      const category1 = hit1.category && hit1.tags ? this.buildCategoryHTML(hit1.category, hit1.tags, hit1.category_id) : "";
      const highlightedTitle1 = hit1._highlightResult.title.value || hit1.title;
      const title1 = this.buildTitleHTML(highlightedTitle1, `/t/${hit1.id}`, hit1.type, hit1.closed);
      const highlightedBlurb1 = hit1._highlightResult.blurb.value || hit1.blurb;
      const content1 = this.buildContentHTML(highlightedBlurb1);
      const template1 = `
          <div class="fps-topic">
            ${title1}
            ${category1}
            <div class="blurb container">
              ${content1}
            </div>
              ${author1}
              ${date1}
          </div>
          `;
      return template1;
    }
    postsHitTemplate(hit1) {
      const highlightedTitle1 = hit1._highlightResult.topic_title?.value || hit1.topic_title;
      const title1 = this.buildTitleHTML(highlightedTitle1, `/p/${hit1.id}`, hit1.type);
      const snippetContent1 = hit1._snippetResult?.raw?.value || hit1?.raw;
      const content1 = hit1.raw ? this.buildContentHTML(snippetContent1) : "";
      const category1 = hit1.category && hit1.tags ? this.buildCategoryHTML(hit1.category, hit1.tags, hit1.category_id) : "";
      const author1 = this.buildAuthorHTML(hit1.author_username);
      const date1 = this.buildDateHTML(hit1.created_at);
      return `
          <div class="post-result">
            <div class="post-result__avatar">
              ${author1}
            </div>
            <div class="post-result__title">
              ${title1}
              ${category1}
            </div>
            <div class="post-result__excerpt">
              <span class="first username">
                ${hit1.author_username}
              </span>
              ${content1}
              ${date1}
            </div>
          </div>
          `;
    }
    chatMessagesHitTemplate(hit1) {
      const authorHTML1 = this.buildAuthorHTML(hit1.author_username);
      const highlightedtitle1 = hit1._highlightResult?.author_username?.value || hit1.author_username;
      const title1 = this.buildUsernameTitle(highlightedtitle1);
      const channel1 = this.buildChannelHTML(hit1.channel_name, hit1.channel_id, hit1.id);
      const date1 = this.buildDateHTML(hit1.created_at);
      const highlightedContent1 = hit1._highlightResult?.raw?.value || hit1?.raw;
      const content1 = hit1.raw ? this.buildContentHTML(highlightedContent1) : "";
      return `
    <div class="chat-result">
      ${channel1}
      ${authorHTML1} 
      <div class="fps-topic">
        ${title1} ${date1}
        <div class="blurb container">
        ${content1}
        </div>
      </div>
    </div>
    `;
    }
    usersHitTemplate(hit1) {
      const authorHTML1 = this.buildAuthorHTML(hit1.username);
      const highlightedtitle1 = hit1._highlightResult?.username?.value || hit1.username;
      const title1 = this.buildUsernameTitle(highlightedtitle1);
      const date1 = this.buildDateHTML(hit1.created_at);
      return `
    <div class="user-result">
      <div class="user-result__user">
        ${authorHTML1}
        <div class="fps-topic">
          ${title1}
        </div>
      </div> 
      <div class="user-result__likes-received --stat">
        ${hit1.likes_received}
      </div>
      <div class="user-result__likes-given --stat">
        ${hit1.likes_given}
      </div>
      <div class="user-result__topics-created --stat">
        ${hit1.topics_created}
      </div>
      <div class="user-result__replies-created --stat">
        ${hit1.posts_created}
      </div>
      <div class="user-result__account-created">
        ${date1}
      </div>
    </div>    
    `;
    }
    buildContentHTML(content1) {
      return `
      <div class="blurb container">
        ${content1}
      </div>
    `;
    }
    buildUsernameTitle(username1) {
      return `
      <div class="topic">
        <a href="/u/${username1}" class="search-link" role="heading">
          <span class="topic-title">
            ${username1}
          </span>
        </a>
      </div>
     `;
    }
    buildTitleHTML(title1, url1, type1, closed1) {
      const svgEnvelope1 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>`;
      const typeIcon1 = type1 === "private_message" ? svgEnvelope1 : "";
      const svgLock1 = `<svg class="lock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>`;
      const closedIcon1 = closed1 ? svgLock1 : "";
      return `
      <div class="topic">
        <a href="${url1}" class="search-link" role="heading">
          <span class="topic-title">
           ${closedIcon1} ${typeIcon1} ${title1}
          </span>
        </a>
      </div>
     `;
    }
    buildDateHTML(date1) {
      const dateValue1 = new Date(date1 * 1000);
      const formattedDate1 = (0, _formatter.relativeAge)(dateValue1);
      return `
      <div class="date">
        <span>${formattedDate1}</span>
      </div>
    `;
    }
    buildAuthorHTML(username1) {
      // TODO: Improve way of getting avatar src.
      const avatarSrc1 = `https://meta.discourse.org/user_avatar/meta.discourse.org/${username1}/48/176214_2.png`;
      return `
        <div class="author">
          <a href="/u/${username1}" data-user-card="${username1}"><img src="${avatarSrc1}" width="48" height="48" class="avatar" title="${username1}"/></a>
        </div>
      `;
    }
    buildTagsHTML(tags1) {
      const tagsHTML1 = [];
      tags1.forEach(tag1 => {
        tagsHTML1.push(`<a href="/tags/${tag1}" class="discourse-tag simple" data-tag-name="${tag1}">${tag1}</a>`);
      });
      const tagsWrapper1 = `
      <div class="discourse-tags" role="list" aria-label="Tags">
        ${tagsHTML1.join("")}
      </div>
    `;
      return tagsWrapper1;
    }
    buildCategoryHTML(category1, tags1, category_id1) {
      const categoryData1 = this.args.categoriesList.find(cat1 => cat1.id === category_id1);
      const categoryParentData1 = categoryData1?.parent_category_id ? this.args.categoriesList.find(cat1 => cat1.id === categoryData1.parent_category_id) : null;
      const categoryParentColor1 = categoryParentData1 ? `--parent-category-badge-color: #${categoryParentData1?.color};` : "";
      const hasParent1 = categoryParentData1 ? "--has-parent" : "";
      return `
      <div class="search-category">
        <a href="/c/${category1}" class="badge-category__wrapper" style="--category-badge-color: #${categoryData1?.color}; ${categoryParentColor1}">
          <span class="badge-category ${hasParent1}">
            <span class="badge-category__name">${category1}</span>
          </span>
        </a>
        ${this.buildTagsHTML(tags1)}
      </div>`;
    }
    buildChannelHTML(channel1, channel_id1, id1) {
      // TODO: Get proper category id and category badge color.
      const chatSvg1 = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z"/></svg>`;
      const channelName1 = channel1 || "-";
      const channelDisplayName1 = channel1 || "message expired";
      const channelUrl1 = `/chat/c/${channelName1}/${channel_id1}/${id1}`;
      const disabled1 = channel1 ? "" : `disabled`;
      const seeIn1 = channel1 ? "See in" : "";
      return `
      <div class="chat-result__channel">
        <a href="${channelUrl1}" ${disabled1} style="--category-badge-color: #00A94F">
            <span class="chat-result__channel-name">${seeIn1} ${chatSvg1} ${channelDisplayName1}</span>
        </a>
      </div>`;
    }
    get hasQuery() {
      return this.dInstantSearch.query.length > 0;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hasQuery}}
          <div class="search-results --{{@searchType}}" role="region">
            {{#if (eq @searchType "users")}}
              <div class="--heading">
                <span>Username</span>
                <span class="--stat">Likes received</span>
                <span class="--stat">Likes given</span>
                <span class="--stat">Topics created</span>
                <span class="--stat">Replies</span>
                <span>Created</span>
              </div>
            {{/if}}
            <@instantSearch.AisInfiniteHits
              @searchInstance={{@searchInstance}}
              @templates={{this.customHitTemplate}}
              @cssClasses={{this.infiniteHitsClasses}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "RNvCMhpz",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasQuery\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"search-results --\",[30,1]]]],[14,\"role\",\"region\"],[12],[1,\"\\n\"],[41,[28,[32,0],[[30,1],\"users\"],null],[[[1,\"          \"],[10,0],[14,0,\"--heading\"],[12],[1,\"\\n            \"],[10,1],[12],[1,\"Username\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"--stat\"],[12],[1,\"Likes received\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"--stat\"],[12],[1,\"Likes given\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"--stat\"],[12],[1,\"Topics created\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"--stat\"],[12],[1,\"Replies\"],[13],[1,\"\\n            \"],[10,1],[12],[1,\"Created\"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[30,2,[\"AisInfiniteHits\"]],null,[[\"@searchInstance\",\"@templates\",\"@cssClasses\"],[[30,3],[30,0,[\"customHitTemplate\"]],[30,0,[\"infiniteHitsClasses\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@searchType\",\"@instantSearch\",\"@searchInstance\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-instant-search/discourse/components/search-results.js",
      "scope": () => [_truthHelpers.eq],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchResults;
});