define("discourse/plugins/discourse-instant-search/discourse/lib/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SEARCH_TYPES = _exports.SEARCH_MODES = void 0;
  const SEARCH_TYPES = _exports.SEARCH_TYPES = {
    topics: "topics",
    posts: "posts",
    users: "users",
    chat_messages: "chat_messages"
  };
  const SEARCH_MODES = _exports.SEARCH_MODES = {
    keyword: "keyword",
    hybrid: "hybrid",
    semantic: "semantic",
    hyde: "hyde"
  };
});