define("discourse/plugins/discourse-instant-search/discourse/instant-search-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("instant-search");
  }
});