define("discourse/plugins/discourse-instant-search/discourse/routes/instant-search", ["exports", "discourse/lib/ajax", "discourse/lib/utilities", "discourse/routes/discourse", "discourse-i18n"], function (_exports, _ajax, _utilities, _discourse, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return _discourseI18n.default.t("search.results_page", {
        term: (0, _utilities.escapeExpression)(this.controllerFor("instant-search").dInstantSearch.query)
      });
    },
    model() {
      return (0, _ajax.ajax)("/instant-search/key");
    }
  });
});