define("discourse/plugins/discourse-instant-search/discourse/services/d-instant-search", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DInstantSearch extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "searchInstance", [_tracking.tracked], function () {
      return null;
    }))();
    #searchInstance = (() => (dt7948.i(this, "searchInstance"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "helper", [_tracking.tracked], function () {
      return this.searchInstance ? this.searchInstance.helper : null;
    }))();
    #helper = (() => (dt7948.i(this, "helper"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "query", [_tracking.tracked], function () {
      return "";
    }))();
    #query = (() => (dt7948.i(this, "query"), void 0))();
  }
  _exports.default = DInstantSearch;
});