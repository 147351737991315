define("discourse/plugins/discourse-instant-search/discourse/components/search-container", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/conditional-loading-spinner", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "discourse/plugins/discourse-instant-search/discourse/lib/constants", "discourse/plugins/discourse-instant-search/discourse/components/search-header", "discourse/plugins/discourse-instant-search/discourse/components/search-results", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _service, _conditionalLoadingSpinner, _ajax, _ajaxError, _debounce, _decorators, _constants, _searchHeader, _searchResults, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SearchContainer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dInstantSearch", [_service.service]))();
    #dInstantSearch = (() => (dt7948.i(this, "dInstantSearch"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "noLoop", [_tracking.tracked], function () {
      return false;
    }))();
    #noLoop = (() => (dt7948.i(this, "noLoop"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "uiState", [_tracking.tracked], function () {
      return null;
    }))();
    #uiState = (() => (dt7948.i(this, "uiState"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    get customMiddleware() {
      const context1 = this;
      return [() => ({
        onStateChange(_ref) {
          let {
            uiState: uiState1
          } = _ref;
          context1.uiState = uiState1;
          // Adds Ember tracking context to the search instance helper
          // so that the property is tracked for changes *when the UI state changes*
          // this is helpful for us to use the helper methods such as getRefinements()
          context1.dInstantSearch.helper = context1.dInstantSearch.searchInstance.helper;
          const searchType1 = context1.args.searchType;
          const currentSearchType1 = uiState1[searchType1];
          if (currentSearchType1?.query) {
            context1.dInstantSearch.query = currentSearchType1.query;
          } else {
            context1.dInstantSearch.query = "";
          }
        },
        subscribe() {},
        unsubscribe() {}
      })];
    }
    async _fetchEmbeddings(uiState1, setUiState1, helper1) {
      const query1 = helper1.getQuery().query;
      const page1 = helper1.getPage();
      if (!query1 || query1?.length === 0 || page1) {
        return setUiState1(uiState1);
      }
      this.loading = true;
      try {
        const embeddings1 = await (0, _ajax.ajax)("/instant-search/embeddings", {
          type: "POST",
          data: JSON.stringify({
            search_query: helper1.getQuery().query,
            hyde: this.args.searchMode === _constants.SEARCH_MODES.hyde
          }),
          contentType: "application/json"
        }).then(response1 => {
          this.loading = false;
          return response1.embeddings;
        });
        if (this.args.searchMode === _constants.SEARCH_MODES.semantic || this.args.searchMode === _constants.SEARCH_MODES.hyde) {
          helper1.setQueryParameter("typesenseVectorQuery", `embeddings:([${embeddings1.join(",")}], k:1000)`).setQueryParameter("query", "*");
          return setUiState1(uiState1);
        } else {
          // hybrid
          helper1.setQueryParameter("typesenseVectorQuery", `embeddings:([${embeddings1.join(",")}], k:1000)`);
          return setUiState1(uiState1);
        }
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      }
    }
    async onStateChange(_ref2) {
      let {
        uiState: uiState1,
        setUiState: setUiState1
      } = _ref2;
      const helper1 = this.dInstantSearch.searchInstance.helper;
      if (this.args.searchMode === _constants.SEARCH_MODES.keyword || this.args.searchType === _constants.SEARCH_TYPES.users || this.args.searchType === _constants.SEARCH_TYPES.chat_messages) {
        helper1.setQueryParameter("typesenseVectorQuery", null);
        return setUiState1(uiState1);
      }
      (0, _debounce.default)(this, this._fetchEmbeddings, uiState1, setUiState1, helper1, 1000);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onStateChange", [_decorators.bind]))();
    initSearchInstance(element1, searchInstance1) {
      this.dInstantSearch.searchInstance = searchInstance1[0];
    }
    static #_6 = (() => dt7948.n(this.prototype, "initSearchInstance", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <@instantSearch.AisInstantSearch
          @apiData={{@apiData}}
          @middleware={{this.customMiddleware}}
          @collectionSpecificSearchParameters={{@searchParameters}}
          @onStateChange={{this.onStateChange}}
          as |Ais|
        >
          <section
            class="search-container instant-search-container"
            {{didInsert this.initSearchInstance Ais.searchInstance}}
          >
            <SearchHeader
              @instantSearch={{@instantSearch}}
              @searchInstance={{Ais.searchInstance}}
              @query={{@query}}
              @searchType={{@searchType}}
              @uiState={{this.uiState}}
              @searchModes={{@searchModes}}
              @searchMode={{@searchMode}}
            >
              <:searchMode>
                {{yield to="searchMode"}}
              </:searchMode>
              <:sortBy>
                {{yield to="sortBy"}}
              </:sortBy>
            </SearchHeader>
    
            <ConditionalLoadingSpinner @condition={{this.loading}} />
    
            <div class="search-advanced">
              <SearchResults
                @loading={{this.loading}}
                @query={{@query}}
                @instantSearch={{@instantSearch}}
                @searchInstance={{Ais.searchInstance}}
                @searchType={{@searchType}}
                @categoriesList={{@categoriesList}}
              />
            </div>
          </section>
        </@instantSearch.AisInstantSearch>
      
    */
    {
      "id": "fYogouOa",
      "block": "[[[1,\"\\n    \"],[8,[30,1,[\"AisInstantSearch\"]],null,[[\"@apiData\",\"@middleware\",\"@collectionSpecificSearchParameters\",\"@onStateChange\"],[[30,2],[30,0,[\"customMiddleware\"]],[30,3],[30,0,[\"onStateChange\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,\"section\"],[24,0,\"search-container instant-search-container\"],[4,[32,0],[[30,0,[\"initSearchInstance\"]],[30,4,[\"searchInstance\"]]],null],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@instantSearch\",\"@searchInstance\",\"@query\",\"@searchType\",\"@uiState\",\"@searchModes\",\"@searchMode\"],[[30,1],[30,4,[\"searchInstance\"]],[30,5],[30,6],[30,0,[\"uiState\"]],[30,7],[30,8]]],[[\"searchMode\",\"sortBy\"],[[[[1,\"\\n            \"],[18,10,null],[1,\"\\n          \"]],[]],[[[1,\"\\n            \"],[18,11,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n        \"],[8,[32,2],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"search-advanced\"],[12],[1,\"\\n          \"],[8,[32,3],null,[[\"@loading\",\"@query\",\"@instantSearch\",\"@searchInstance\",\"@searchType\",\"@categoriesList\"],[[30,0,[\"loading\"]],[30,5],[30,1],[30,4,[\"searchInstance\"]],[30,6],[30,9]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[4]]]]],[1,\"\\n  \"]],[\"@instantSearch\",\"@apiData\",\"@searchParameters\",\"Ais\",\"@query\",\"@searchType\",\"@searchModes\",\"@searchMode\",\"@categoriesList\",\"&searchMode\",\"&sortBy\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-instant-search/discourse/components/search-container.js",
      "scope": () => [_didInsert.default, _searchHeader.default, _conditionalLoadingSpinner.default, _searchResults.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchContainer;
});