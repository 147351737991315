define("discourse/plugins/discourse-instant-search/discourse/controllers/instant-search", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/discourse-instant-search/discourse/lib/constants"], function (_exports, _tracking, _controller, _object, _service, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstantSearch extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dInstantSearch", [_service.service]))();
    #dInstantSearch = (() => (dt7948.i(this, "dInstantSearch"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "categoryWeights", [_tracking.tracked], function () {
      return this.model.categories;
    }))();
    #categoryWeights = (() => (dt7948.i(this, "categoryWeights"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "searchMode", [_tracking.tracked], function () {
      return _constants.SEARCH_MODES.keyword;
    }))();
    #searchMode = (() => (dt7948.i(this, "searchMode"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "searchParameters", [_tracking.tracked], function () {
      return {
        posts: {
          query_by: "raw,topic_title",
          query_by_weights: "3,1",
          exclude_fields: "embeddings",
          facet_by: "author_username,category,tags,type,allowed_users,allowed_groups",
          sort_by: `_text_match(buckets: 5):desc,${this.categoryWeights},created_at:desc`
        },
        topics: {
          query_by: "title,blurb",
          exclude_fields: "embeddings",
          facet_by: "author_username,category,tags,type,allowed_users,allowed_groups",
          sort_by: `_text_match(buckets: 5):desc,${this.categoryWeights},created_at:desc`
        },
        users: {
          query_by: "username,name",
          facet_by: "trust_level,groups",
          sort_by: "_text_match:desc,trust_level:desc,likes_received:desc"
        },
        chat_messages: {
          query_by: "raw",
          facet_by: "author_username,channel_name"
        }
      };
    }))();
    #searchParameters = (() => (dt7948.i(this, "searchParameters"), void 0))();
    get searchModes() {
      return [{
        label: "Keyword Search",
        value: _constants.SEARCH_MODES.keyword
      }, {
        label: "Hybrid Search",
        value: _constants.SEARCH_MODES.hybrid
      }, {
        label: "Semantic Search",
        value: _constants.SEARCH_MODES.semantic
      }, {
        label: "Hyde Search",
        value: _constants.SEARCH_MODES.hyde
      }];
    }
    changeSearchMode(newSearchMode) {
      this.searchMode = newSearchMode;
      if (this.dInstantSearch.query?.length > 0) {
        this.dInstantSearch.helper.search();
      }
      if (newSearchMode === _constants.SEARCH_MODES.semantic || newSearchMode === _constants.SEARCH_MODES.hyde) {
        this.searchParameters = {
          posts: {
            query_by: "raw,topic_title",
            query_by_weights: "3,1",
            exclude_fields: "embeddings",
            facet_by: "author_username,category,tags,type,allowed_users,allowed_groups"
          },
          topics: {
            query_by: "title,blurb",
            exclude_fields: "embeddings",
            facet_by: "author_username,category,tags,type,allowed_users,allowed_groups"
          },
          users: {
            query_by: "username,name",
            facet_by: "trust_level,groups"
          },
          chat_messages: {
            query_by: "raw",
            facet_by: "author_username,channel_name"
          }
        };
      } else if (newSearchMode === _constants.SEARCH_MODES.keyword) {
        this.searchParameters = {
          posts: {
            query_by: "raw,topic_title",
            query_by_weights: "3,1",
            exclude_fields: "embeddings",
            facet_by: "author_username,category,tags,type,allowed_users,allowed_groups",
            sort_by: `_text_match(buckets: 20):desc,${this.categoryWeights}`
          },
          topics: {
            query_by: "title,blurb",
            exclude_fields: "embeddings",
            facet_by: "author_username,category,tags,type,allowed_users,allowed_groups",
            sort_by: `_text_match(buckets: 10):desc,${this.categoryWeights}`
          },
          users: {
            query_by: "username,name",
            facet_by: "trust_level,groups",
            sort_by: "_text_match:desc,trust_level:desc,likes_received:desc"
          },
          chat_messages: {
            query_by: "raw",
            facet_by: "author_username,channel_name"
          }
        };
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "changeSearchMode", [_object.action]))();
  }
  _exports.default = InstantSearch;
});